import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import React, { useState } from "react";
// import './App.css';
import Home from "./views/home";
import NotFound from "./views/notfound";
import Header from "./views/components/header";
import StaticPage from "./views/static-page";
import Footer from "./views/components/footer";
// import SettingsIcon from '@material-ui/icons/Settings';
// import InfoIcon from '@material-ui/icons/Info';
// import { ReactComponent as FormIcon } from '../src/public/images/form-icon.svg';
import './public/css/quick-link.scss'
import './public/css/media.scss'
import './public/css/font.css';
import './public/css/font-awesome.css';
import './public/css/bootstrap.min.css';
import './public/css/style-contrast.scss';
import GetQuotes from './views/get-quotes-page';
import { incfont, decfont, orgfont } from './public/js/font-sizer.js'
import AllSearchResults from './views/all-search-results';
import UsStocksQuotes from './views/us-stocks-quotes';
import { ReactComponent as Settings } from '../src/public/images/settings.svg';
import ListOfUnderlying from './views/list-of-underlying';
import OptionChart from './views/components/option-chart';
import globejoypixels from '../src/public/images/globe-joypixels.gif';
const renderContent = (routerProps, headerRef) => {
  try {
    const pathname = routerProps.location.pathname;
    return (
      <StaticPage {...routerProps} headerRef={headerRef} pathname={pathname} />
    );
  } catch (e) {
    console.log("err", e);
    return <NotFound />;
  }
};

function App() {
  const headerRef = React.createRef();

  //localStorage used for storing current contrast value in browser
  const [contrastClass, setContrastClass] = useState(
    localStorage.getItem("NseIfscContrast")
  );

  const [toggleSetting, setToggleSetting] = useState(false);
  const [toggleHelp, setToggleHelp] = useState(false);

  return (
    <Router>
      <div className={contrastClass}>
        {/* Common Header */}
        <Header ref={headerRef}></Header>

        {/* Side Toggle - Quick Links */}
        <div role="navigation" aria-label="Accessibility">
          <ul className="sidetoggle">

           <a href="https://www.nseix.com/membership/RTP">
<li className="rtp-list">
              <img src={globejoypixels} alt="RTP-logo" height="50" width="50" />
              <b style={{marginLeft:'5px',marginTop:'6px'}}> RTP</b>
              <span className="sidetoggle-text"
                onClick= {() => {
                  setToggleHelp(false);
                }}
              >
                Remote Trading Participant
              </span>
            </li></a>


            <li className="settingsHover">
              <Settings
                className="settings-icon"
                onClick={() => {
                  setToggleSetting(!toggleSetting);
                  setToggleHelp(false);
                }}
              />
              <span
                className="sidetoggle-text"
                onClick={() => {
                  setToggleSetting(!toggleSetting);
                  setToggleHelp(false);
                }}
              >
                Settings
              </span>

              {toggleSetting ? (
                /* Font Sizer */
                <div className="toggling-desc">
                  <p>Font-size</p>
                  <div className="setting">
                    <div
                      onClick={() => {
                        incfont();
                      }}
                      title="Text Increase"
                    >
                      A+
                    </div>
                    <div
                      onClick={() => {
                        orgfont();
                      }}
                      title="Text Reset"
                    >
                      A
                    </div>
                    <div
                      onClick={() => {
                        decfont();
                      }}
                      title="Text Decrease"
                    >
                      A-
                    </div>
                  </div>

                  {/* High Contrast */}
                  <p> Contrast</p>
                  <div className="setting" id="contrast">
                    <div
                      onClick={() => {
                        localStorage.setItem(
                          "NseIfscContrast",
                          "body_contrast"
                        );
                        setContrastClass("body_contrast");
                      }}
                      title="High Contrast"
                    >
                      High Contrast
                    </div>
                    <div
                      onClick={() => {
                        localStorage.setItem("NseIfscContrast", "");
                        setContrastClass("");
                      }}
                      title="Reset"
                    >
                      Reset
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </li>

            {/* <li><InfoIcon /><span className="sidetoggle-text">Notifications</span></li>
          <li><FormIcon /><span className="sidetoggle-text">Forms</span></li> */}
            {/* <li><BiPhoneCall /><span className="sidetoggle-text">Contact Us</span></li> */}

            {/* Help */}
            {/* Screen Reader */}
            {/* <li><InfoIcon onClick={() => { setToggleHelp(!toggleHelp); setToggleSetting(false) }} />
              <span className="sidetoggle-text" onClick={() => { setToggleHelp(!toggleHelp); setToggleSetting(false) }}>Help</span>
              {toggleHelp ?
                
                <div className="toggling-desc">
                  <div className="setting" id="contrast">
                    <div href="#" title="Screen Reader">Screen Reader</div>
                  </div>
                </div> : ""}
            </li> */}
          </ul>
        </div>

        {/* Main Body */}
        <div role="main">
          <Switch>
            {/* Homepage Route & Redirect */}
            <Redirect from="/homepage-ifsc" to="/" />
            <Route exact path={["/", "/home", "/index.html"]}>
              <Home headerRef={headerRef} />
            </Route>

            {/* Error Page */}
            <Route exact path="/404">
              <NotFound headerRef={headerRef} />
            </Route>

            {/* All Search Results */}
            <Route
              exact
              path="/search"
              render={(routerProps) => (
                <AllSearchResults
                  headerRef={headerRef}
                  {...routerProps}
                  isAuthed={true}
                />
              )}
            />

            {/* Get Quotes Route*/}
            {/* Equity */}
            <Route
              exact
              path={"/get-quotes/:type"}
              render={(routerProps) => (
                <GetQuotes
                  headerRef={headerRef}
                  {...routerProps}
                  isAuthed={true}
                />
              )}
            />
            {/* Bond Futures */}
            <Route
              exact
              path={"/bondderivatives-getquote"}
              render={(routerProps) => (
                <GetQuotes
                  headerRef={headerRef}
                  {...routerProps}
                  isAuthed={true}
                />
              )}
            />
            {/* Currency */}
            <Route
              exact
              path={"/currency-getquote"}
              render={(routerProps) => (
                <GetQuotes
                  headerRef={headerRef}
                  {...routerProps}
                  isAuthed={true}
                />
              )}
            />

            {/* Us Stocks Quotes - Stocks/Etfs Page */}
            <Route
              exact
              path={"/us-quotes/:type"}
              render={(routerProps) => (
                <UsStocksQuotes
                  headerRef={headerRef}
                  {...routerProps}
                  isAuthed={true}
                />
              )}
            />

            <Route exact path={"/markets/about-our-products/list-of-underlying/equity-derivatives"} render={(routerProps) => (
              <ListOfUnderlying headerRef={headerRef} {...routerProps} isAuthed={true} />
            )} />

            {/* Option Chain Chart */}
            <Route exact path={"/option-chain-chart"} render={(routerProps) => (
            <OptionChart headerRef={headerRef} {...routerProps} isAuthed={true} />
          )} />
            {/* Holiday Redirect */}
            <Redirect from="/holiday_list-ifsc" to="/resources/holidays" />

            {/* All other Pages (Static, Dynamic) Route */}
            <Route
              path="/"
              render={(routerProps) => renderContent(routerProps, headerRef)}
            />
          </Switch>
        </div>

        {/* Common Footer */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
