import { React, useEffect, useState } from 'react';
import CSVDownloader from './components/download-csv';

const ForeignCurrencyBonds = () => {

    const [bondData, setBondData] = useState([]);
    const baseApi = '/api/bonds?type=FOREIGN_CURRENCY';

    //number animation
    const [number, setNumber] = useState(0);
    const [target, setTarget] = useState(0);
    const duration = 2000;


    const fetchData = async () => {
        try {
            const resp = await fetch(baseApi);
            const jsonData = await resp.json();
            console.log("JSON data is >>>",typeof jsonData[0].LISTINGDATE);
            setBondData(jsonData);
            setTarget(jsonData.length)
            numberAnimation(jsonData.length);
        } catch (error) {
            console.log('error in fetching data', error);
        };
    };

    const numberAnimation = async (target) => {
        const startTime = performance.now();
        const animate = (currentTime) => {
            const elapsed = currentTime - startTime;
            const progress = Math.min(elapsed / duration, 1);
            const newValue = Math.floor(progress * target);

            setNumber(newValue);
            if (progress < 1) {
                requestAnimationFrame(animate);
            }
        }
        requestAnimationFrame(animate);
    }

    useEffect(() => {
        fetchData();
    }, [target, duration]);


    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="static-para">
                        <p>List your Foreign Currency&nbsp; Bonds on India’s Global Exchange</p>
                        <p>A Foreign Currency&nbsp; Bond is issued in a currency other than Indian
                            Rupee</p>
                        <p>IFSCA (Listing) Regulations, 2024 governs the
                            issues relating to Foreign Currency&nbsp; Bonds.</p>
                    </div>
                </div>
                <div className="col-md-3 mb-2">
                    <div className="issueinfo-card">
                        <div className="issue-img"><img
                            src="https://www.nseix.com/nseixcms-static-files/sites/default/files/2022-01/Capture_3.PNG"
                            alt="capture" /></div>
                        <p>MTN Established over USD 50.8 Billion</p>
                    </div>
                </div>
                <div className="col-md-3 mb-2">
                    <div className="issueinfo-card">
                        <div className="issue-img"><img
                            src="https://www.nseix.com/nseixcms-static-files/sites/default/files/2022-01/Capture1_6.PNG"
                            alt="capture" /></div>
                        <p>Option to raise funds in multiple currencies</p>
                    </div>
                </div>
                <div className="col-md-3 mb-2">
                    <div className="issueinfo-card">
                        <div className="issue-img">
                            <img
                                src="https://www.nseix.com/nseixcms-static-files/sites/default/files/2022-01/Capture2_3.PNG"
                                alt="capture" /></div>
                        <p>No minimum market capitalisation requirement</p>
                    </div>
                </div>
                <p className="col-md-4 my-3"><a className="btn flat-button-orange external" target="_blank"
                    rel="noopener noreferrer" href="/listing/debt/listing-process"
                    title="How to list Foreign Currency&nbsp; Bonds on our Platform">How to list
                    Foreign Currency&nbsp; Bonds on our Platform</a></p>
                <div className="col-md-12 pt-2 mb-2">
                    <div className="notes-section">
                        <h3 className="notes-title">List of Foreign Currency&nbsp; Bonds</h3>
                    </div>
                </div>
                <div className="col-md-12 pt-2 mb-2">
                    <div className="notes-section">
                        <h3 className="notes-title">{bondData != undefined && bondData.length > 0 && number}+</h3>
                    </div>
                </div>
                <div className="d-flex justify-content-end ">
                    <div className="mr-2">
                        <CSVDownloader data={bondData} csvname={"ForeignCurrencyBonds"} />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="responsive-static-table static-table" id="ForeignCurrencyBonds">
                        <table border="1" cellPadding="1" cellSpacing="1">
                            <thead>
                                <tr>
                                    <th scope="col">Issuer</th>
                                    <th scope="col">ISIN</th>
                                    <th scope="col">Issue Size</th>
                                    <th scope="col">Coupen Rate(%)</th>
                                    <th scope="col">Issue Date</th>
                                    <th scope="col">Credit Rating</th>
                                    <th scope="col">Listing Date</th>
                                    <th scope="col">Maturity Date</th>
                                    <th scope="col">Under MTN Programme</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bondData != undefined && bondData.length > 0 && bondData.map((bond) =>
                                    <tr>
                                        <td><a href={`/listing/issuer-details?issuerName=${bond.ISSUER}`} target="_blank">{bond.ISSUER}</a></td>
                                        <td>{bond.ISIN}</td>
                                        <td>{bond.ISSUE_SIZE}</td>
                                        <td>{bond.COUPONRATE}</td>
                                        <td>{bond.ISSUEDATE}</td>
                                        <td>{bond.CREDITRATING}</td>
                                        <td>{bond.LISTINGDATE}</td>
                                        <td>{bond.MATURITYDATE}</td>
                                        <td>{bond.MTN == null ? '-' : bond.MTN}</td>
                                    </tr>
                                )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ForeignCurrencyBonds;
